import Vue from "vue";
import Router from "vue-router";
import { auth } from "./firebase";
import { getDatabase, ref, child, get, set, update } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const dbRef = ref(getDatabase());
const auth_ = getAuth();

Vue.use(Router);

const routes = [
  {
    path: "/dashboard",
    redirect: "/dashboard",
    component: () => import("@/view/layout/Layout"),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/view/pages/Dashboard.vue"),
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/view/pages/Builder.vue"),
      },

      {
        path: "/user",
        name: "user",
        component: () => import("@/view/pages/user/User.vue"),
        beforeEnter: (to, from, next) => {
          return get(child(dbRef, `users/` + auth_.currentUser.uid))
            .then((snapshot) => {
              if (snapshot.exists()) {
                if (snapshot.val().enableusercreation == true) {
                  return next();
                } else {
                  return next("/dashboard");
                }
              }
            })
            .catch((error) => {
              console.error(error);
            });
        },
        children: [
          {
            path: "add-user",
            name: "add-user",
            component: () => import("@/view/pages/user/AddUser.vue"),
          },
          {
            path: "add-admin-user",
            name: "add-admin-user",
            component: () => import("@/view/pages/user/AddAdminUser.vue"),
          },
          {
            path: "chat-notification",
            name: "chat-notification",
            component: () => import("@/view/pages/user/ChatNotification.vue"),
          },
        ],
      },
      {
        path: "/chats",
        name: "chats",
        component: () => import("@/view/pages/chats/Chats.vue"),
        children: [
          {
            path: "manage-chats",
            name: "manage-chats",
            component: () => import("@/view/pages/chats/ManageChats.vue"),
          },
        ],
      },
      {
        path: "/notification",
        name: "notification",
        component: () => import("@/view/pages/notification/Notification.vue"),
        children: [
          {
            path: "manage-notification",
            name: "manage-notification",
            component: () =>
              import("@/view/pages/notification/ManageNotification.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/custom-error",
    name: "error",
    component: () => import("@/view/pages/error/Error.vue"),
    children: [
      {
        path: "error-1",
        name: "error-1",
        component: () => import("@/view/pages/error/Error-1.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/view/pages/auth/login_pages/Login-1"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/view/pages/auth/login_pages/Login-1"),
      },
      {
        name: "register",
        path: "/register",
        component: () => import("@/view/pages/auth/login_pages/Login-1"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/view/pages/error/Error-1.vue"),
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some((x) => x.meta.authRequired);

//   if (requiresAuth && !auth.currentUser) {
//     next("/login");
//   } else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (auth.currentUser) {
      next();
    } else {
      // alert('You must be logged in to see this page');
      next({
        path: "/",
      });
    }
  } else {
    next();
  }
});

export default router;
